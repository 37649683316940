import { 
    defineComponent, 
    reactive, 
    onMounted, 
    getCurrentInstance, 
    ComponentInternalInstance,
    onUnmounted,
} from 'vue'
import { SET_RESUME } from '@/utils/constant'
import { ages,getStates } from '@/utils/index'
import { Dialog } from 'vant'
import { 
    recover, 
    eliminate, 
    taskEntry, 
    taskFinish, 
    remaining, 
    browseAndInterview, 
    taskDetail  
} from '@/api/index'
import { useRouter, useRoute } from 'vue-router'

import CashierWidget from '@/components/cashier'
//import { useStore } from 'vuex'

import './index.less'


const PhoneBar = (props) => {
    console.log(props,'手动阀')
    return (
        <div class='fw-phone-container'>
            <div class='fw-phone-box'>
                <div class='fw-phone-left'>
                    <div class='fw-icon-box'>
                        <img class='fw-phone-icon' src='https://img.fanwoon.com/cashier_2.png' />
                    </div>
                    <div>
                        <div class='fw-ctanct-name'>{props.data.candidateInfo.name}</div>
                        <div class='fw-ctanct-phone'>{props.data.candidateInfo.phone}</div>
                    </div>
                </div>
                <div class='fw-phone-right'>
                    <div class='fw-phone-header'>对方在等你电话</div>
                    <a href={`tel:${props.data.candidateInfo.phone}`} class='fw-phone-number-font'>拨打电话面试</a>
                </div>
            </div>
        </div>
    )
}

export default defineComponent({
    name: 'ResumeInfo',
    setup () {
        //const store = useStore()
        const { appContext } = getCurrentInstance() as ComponentInternalInstance
        const proxy = appContext.config.globalProperties
        const router = useRouter()
        const route = useRoute()

        onMounted(() => {
            _remaining()
           _taskDetail(route.query.id)
        })

        onUnmounted(() => {
            localStorage.removeItem(SET_RESUME)
        })

        const balanceMoney = reactive({
            data: {}
        })
        const state: any = reactive({
            info: {
                candidateInfo: {}
            },
            reason: '',
            show: false,
            title: '',
            money: 0,
            tjMoney: 0,
            koukuanMoney: 0, //扣款
            showCashCenter: false, //是否显示扣款弹框
            from: 'first'
        })

        const _taskDetail = async (id:any) => {
            const res:any = await taskDetail({data: id})
           // remaining()
            if (!res)return 

            const obj = res.data
            
            if (obj.recommendReward && obj.recommendRewardRole) {
                //debugger
                const price = JSON.parse(obj.recommendRewardRole)
                if (price[0]) {
                    state.tjMoney += price[0]
                } 
                if (price[200]) {
                    state.tjMoney += price[200]
                }
                if (price[400]) {
                    state.tjMoney += price[400]
                }
            }
            state.info = obj


             //const { money, info } = state
            const tjMoney = state.tjMoney
            if (!obj.payStatus) {
                state.koukuanMoney = (Number(tjMoney) + 0)  //Number(5.99)
            } else {
                state.koukuanMoney = (Number(tjMoney))
            }
            console.log(res, '招聘职位.')
        }

        const _remaining = async () => {
            const res:any  = await remaining()
            localStorage.setItem('M', res.data)
            state.money = Number(res.data)
            balanceMoney.data['money'] = Number(res.data)
        }

        const getAges = (str: string) => {
            if(!str)return
            return ages(str)
        }

        const onGetStates = (key: number) => {
            return getStates(key)
        }

        const _recover = async () => {
            const res: any = await recover({data: state.info.id })
            if (res.code  !== 0) {
               return proxy.$toast(`操作${res.message}`);
            }
            proxy.$toast(`操作${res.message}`);
            let timer: any = setTimeout(() => {
                clearTimeout(timer)
                timer = null 
                router.back()
            }, 1000)
        }
        
        const tishiUI = () => {
            return (
                <div>
                    <img class='tishi-icon' src='https://img.fanwoon.com/tishi.png' />
                    <div class="tishi-info">
                        是否将该求职者恢复至
                        <span class='tishi-statue'>
                            { onGetStates(state.info.eliminateStatus) }
                        </span>
                        阶段
                    </div>
                </div>
            )
       }

        //恢复至原阶段
        const onRecovery = () => {
            Dialog.confirm({
                message: tishiUI,
                closeOnClickOverlay: true,
            }).then(() => {
                _recover()
            }).catch(() => {
                // on cancel
                console.log('cancel')
        });
        }

        const outUI = () => {
            if (!state.info.eliminateTime) return 
           
            return (
                <ul class="out-container">
                    <li><span class="out-font">淘汰时间:</span> { state.info.eliminateTime }</li>
                    <li><span class="out-font">淘汰阶段:</span>{ onGetStates(state.info.eliminateStatus) }</li>
                    <li><span class="out-font">淘汰原因:</span> { state.info.eliminateReason }</li>
                </ul>
            )
        }

        const outInterviewUI = () => {
            return (
                <div>
                    <img class='tishi-icon' src='https://img.fanwoon.com/tishi.png' />
                    <div class="tishi-info-box">
                        <div class="out-font">淘汰阶段：<span>{ onGetStates(state.info.status) }</span></div>
                        <div>
                            <span class="important-box">*</span>
                            淘汰原因
                            <input v-model={state.reason} class="input-box" placeholder='请填写淘汰原因' />
                        </div>
                    </div>
                </div>
            )
        }

         //淘汰操作
        const onOut = async () => {
            const res: any = await eliminate({id: state.info.id, name: state.reason})
            if (res.code  !== 0) {
                return proxy.$toast(`操作${res.message}`);
             }
             proxy.$toast(`操作${res.message}`);
             let timer: any = setTimeout(() => {
                 clearTimeout(timer)
                 timer = null 
                 state.reason = ''
                 router.back()
             }, 1000)
        }

        const onSubmit = () => {
            console.log('tongguo.')
            onOut()
        }

        const openInterviewDailog = () => {
            Dialog.confirm({
                message: outInterviewUI,
                closeOnClickOverlay: true,
                beforeClose :(res) => {
                    if (res === 'cancel') {
                        return true
                    } else if (!state.reason && res === 'confirm') {
                        proxy.$toast(`淘汰原因不能为空`);
                        return false
                    }
                    console.log(res)
                    return true
                }
            }).then(() => {
                onSubmit()
            }).catch(() => {
                // on cancel
                state.reason = ''
                console.log('cancel')
            });
        }

        //通过简历筛选
        const onInterview = async(bool) => {
            const res: any = await browseAndInterview ({data: {id:  state.info.id, name: bool}})
            
            if (res.code  !== 0) {
                return proxy.$toast(`${res.message}`);
             }
             proxy.$toast(res.message + '通过筛选简历!');
             let timer: any = setTimeout(() => {
                 clearTimeout(timer)
                 timer = null 
                 state.reason = ''
                 replaceParamVal("state", "200")
             }, 1000)
        }

        //接受入职
        const onTaskEntry = async () => {
            const res: any = await taskEntry({data: state.info.id})

            if (res.code  !== 0) {
                return proxy.$toast(`操作失败${res.message}`);
             }
             proxy.$toast(`已${res.message}接受入职`);
             let timer: any = setTimeout(() => {
                 clearTimeout(timer)
                 timer = null 
                 state.reason = ''
                 location.reload()
                // router.back()
             }, 1000)
        }

        // 完成入职
       const onTaskFinish = async(bool) => {
            const res: any = await taskFinish({data: {id:  state.info.id, name: bool}})
            if (res.code  !== 0) {
                return proxy.$toast(`操作失败${res.message}`);
             }
             proxy.$toast(`已${res.message}完成入职`);
             let timer: any = setTimeout(() => {
                 clearTimeout(timer)
                 timer = null 
                 state.reason = ''
                 replaceParamVal("state", "400")
             }, 1000)
        }

        const nextInterviewUI = () => {
            let str = ''
            if (Number(state.info.status) === 0) {
                str = '面试阶段'
            } else if (Number(state.info.status) === 200) {
                str = '入职阶段'
            } else if (Number(state.info.status) === 400) {
                str = '报到上岗'
            }
            return (
                <div>
                    <img class='tishi-icon' src='https://img.fanwoon.com/tishi.png' />
                    <div class="tishi-info">
                        该求职者将进入{ str }，是否确定？
                    </div>
                </div>
            )
        }

        //替换指定传入参数的值,paramName为参数,replaceWith为新值
        const replaceParamVal = (paramName:any,replaceWith:any) => {
            const oUrl = window.location.href.toString();
            const re=eval('/('+ paramName+'=)([^&]*)/gi');
            const nUrl:any = oUrl.replace(re,paramName+'='+replaceWith);
            window.location = nUrl;
            window.location.href=nUrl
        }

        //预扣款 - 收银台
        const onOpenViewDailog = () => state.showCashCenter = !state.showCashCenter;

        //显示弹框逻辑 - 已报道上岗
        const onFinishCashierBar = () => {
            const { recommendReward, recommendRewardRole, withheld } = state.info
            //如果没有推荐奖金，则直接二次弹框确认
            if (!recommendReward || (recommendReward && recommendRewardRole && withheld)) {
                //openViewDailog()
                openDialogConfirm(false)
                return
            }
            state.from = 'last' //设置来源，last为最后一个环节，已报道上岗
            //如何设置了推荐奖金，预扣为勾选，则二次预扣弹框提示
            if (recommendReward && recommendRewardRole && !withheld) {
                onOpenViewDailog()//再次显示预扣款弹框
                return
            }

            /* //此处为，已扣除预扣款项，直接提示二次确认弹框
            if (recommendReward && recommendRewardRole && withheld) {
                onOpenViewDailog()//再次显示预扣款弹框
                return
            } */
        }

        //通过简历筛选 - 弹框业务逻辑        
        const onInterviewService = (bool) => {
            const { recommendReward, recommendRewardRole } = state.info
            //勾选了设置推荐奖金，但是奖金没有填写 （此处为前置条件）
            if (recommendReward && !recommendRewardRole) {
                onInterview(false) //预扣则为false
                return
            }
            onInterview(bool)
        }

        //已报道上岗- 弹框业务逻辑    
        const onFinishInterviewService = (bool) => {
            const { recommendReward, recommendRewardRole } = state.info
            //勾选了设置推荐奖金，但是奖金没有填写 （此处为前置条件）
            if (recommendReward && !recommendRewardRole) {
                onTaskFinish(false) //预扣则为false
                return
            }
            onTaskFinish(bool)
        } 

        const openDialogConfirm = (bool) => {
            Dialog.close()
            Dialog.confirm({
                message: nextInterviewUI,
                closeOnClickOverlay: true,
            }).then(() => { 
                if (Number(state.info.status) === 0) {
                    onInterview(bool)
                } else if (Number(state.info.status) === 200) {
                    onTaskEntry()
                } else if (Number(state.info.status) === 400) {
                    onTaskFinish(false)
                }
                localStorage.removeItem(SET_RESUME)
            });
        }

        //处理简历
        const onHandleCashier = (bool) => {
            Dialog.close()
            Dialog.confirm({
                message: nextInterviewUI,
                closeOnClickOverlay: true,
            }).then(() => { 
                const handleResume = new Map([
                    [0, ()=> onInterviewService(bool)],
                    [200, ()=> onTaskEntry()],
                    [400, ()=> onFinishInterviewService(bool)]
                ])
                handleResume.get(Number(state.info.status))()
                localStorage.removeItem(SET_RESUME)
            });
        }
        /* const openViewDailog = (bool) => {
            if (state.info.status !== 0) {
                openDialogConfirm(bool)
            }
        } */
        const shorName = (name: string) => {
            if (name) {
                return name.length > 8 ? name.substring(0, 8) + '...' : name
            }
        }

        /**
         * 显示底部按钮
         */
        const footerBarUI = () => {
            if (Number(state.info.status) === 0) {
                return (
                    <div class="footer-box">
                        <button class="out-btn" onClick={openInterviewDailog}>淘汰</button>
                        <button class="interview-btn" onClick={ onOpenViewDailog }>邀约面试</button>
                    </div>
                )
            }
            if (Number(state.info.status) === 200) {
                return (
                    <div class="footer-box">
                        <button class="out-btn" onClick={openInterviewDailog}>未通过面试</button>
                        <button class="interview-btn" onClick={ onHandleCashier }>通过全部面试</button>
                    </div>
                )
            }
            if (Number(state.info.status) === 400 && !state.info.finish) {
                return (
                    <div class="footer-box">
                        <button class="out-btn" onClick={openInterviewDailog}>未报到</button>
                        <button class="interview-btn" onClick={ onFinishCashierBar }>已报到上岗</button>
                    </div>
                )
            }
            if (Number(state.info.status) === 10000) {
                return (
                    <div class="footer-bar-box">
                        <button class="reinit-btn" onClick={onRecovery}>恢复至原阶段</button>
                    </div>
                )
            }
        }

        return () => (
            
            <div class="resumeinfo-page">    
                <div class={`top-header-page flex-box justify-content-between ${state.info.talentCustomerId ? 'pb28': ''}`}>
                    <div>简历详情</div>
                    <img class="logo" src="https://img.fanwoon.com/new_logo.png" />
                </div>  
                <div class="content-box">
                    {
                        !state.info.talentCustomerId ? '' : (
                            <div class="refer-box">
                                <span>该求职者由</span>
                                <span class="small-avatar">
                                    <img src={state.info.talentImg} />
                                </span>
                                <span class="refer-name">{state.info.talentName}</span>
                                <span>为您推荐</span>
                            </div>
                        )
                    }
                    
                    <div class="person-box flex-box">
                        <div class="person-pic">
                            <img src={state.info.candidateInfo.imgUrl} />
                        </div>
                        <div>
                            <div class="person-name">
                                <span class="name-title">{shorName(state.info.candidateInfo.name)}</span>
                                <span>{getAges(state.info.candidateInfo.birth)}岁</span>｜
                                <span>{state.info.candidateInfo.gender === 1 ? '男' : '女'}</span>
                                {
                                    !state.info.candidateInfo.startWork ? null : 
                                    <span>｜{state.info.candidateInfo.startWork}年工作经验</span>
                                }
                                
                            </div>
                            <div class="secod-bar">
                                手机：
                              {/*   {state.info.candidateInfo.status} */}
                                {
                                    !state.info.payStatus ? 
                                    (
                                        <span class='mark-box'><img src='https://img.fanwoon.com/jiesuo%402x.png' alt=''/><span>邀约面试解锁联系方式</span></span>
                                    ) : state.info.candidateInfo.phone
                                }
                            </div>
                            <div style={{fontSize: '12Px'}}>邮箱：{!state.info.candidateInfo.email ? '无' : state.info.candidateInfo.email}</div>
                        </div>
                    </div>
                    <ul class="list-box">
                        <li>
                            <div class="title-bar">个人优势</div>
                            <div class="person-des">
                                <van-field
                                    class="textarea-box"
                                    v-model={ state.info.candidateInfo.advantage }
                                    rows="1"
                                    autosize
                                    readonly
                                    type="textarea"
                                />
                            </div>
                        </li>
                        <li>
                            <div class="title-bar">工作经历</div>
                            {
                                state.info.candidateInfo.businesses ? (
                                    state.info.candidateInfo.businesses.map((item: any, index: number) => {
                                        return (
                                            <div key={index}>
                                                <div class="flex-box justify-content-between align-items-center">
                                                <div class="company-name"><span>{item.company}</span><span class="job-title">{item.jobTitle}</span></div>
                                                <div class="time-box">{item.beginDate} 至 {item.endDate.indexOf('5000-01') > -1 ? '至今' :  item.endDate}</div>
                                                </div>
                                                <div class="job-infos-box">
                                                    {item.description}
                                                </div>
                                            </div>
                                        )
                                    })
                                ) : null
                            }
                        </li>
                        <li>
                            <div class="title-bar">教育经历</div>
                            {
                                state.info.candidateInfo.educations ? (
                                    state.info.candidateInfo.educations.map((item: any, index: number) => {
                                        return (
                                            <div class="flex-box justify-content-between align-items-center" key={index}>
                                                <div class="company-name"><span>{item.school}</span><span class="job-title">{item.profession}</span><span class="job-title">{item.education}</span></div>
                                                <div class="time-box">{item.beginDate} 至 {item.endDate.indexOf('5000-01') > -1 ? '至今' :  item.endDate}</div>
                                            </div>
                                        )
                                    })
                                ) : null
                            }
                            
                        </li>
                    </ul>

                    {
                        state.info.status == 10000 ? (outUI()) : null
                    }
                </div>

                {
                    !state.info.payStatus || state.info.finish ? null : <PhoneBar data={state.info} />
                }

                { footerBarUI() }

                    <CashierWidget 
                        show={state.showCashCenter} 
                        data={state.info}
                        money={state.money}
                        from={state.from}
                        onCancel={()=> onOpenViewDailog()}
                        onSubmit={(e) => onHandleCashier(e) }
                        
                    />       
            </div>
        )
    }
})