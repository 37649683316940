
import { ref, defineComponent, computed, toRefs } from 'vue'
import { useAddUpMoney } from '@/api/fanwoon'
import css from './index.module.less'


const ButtonWidget = defineComponent({
    props: {
        balance: Number, //余额
        addUp: Number,
        from: String,
        advance: { //是否勾选预扣
            type: Boolean,
            required: true,
        }

    },
    setup(props, { attrs }) {
       const onCancel = () =>  attrs.onCancel()
       const onSubmit= () => attrs.onSubmit(props.advance)
       
       const EnoughButton =() => {
            let money =  Number(props.addUp)
            //props.from === 'first' && Number(props.balance)
    
            if (props.from === 'first' && Number(props.balance) < Number(money)) {
                return <button className={css.subBtn} onClick={
                    () => window.location.href ='/amount'
                }>余额不足去充值</button>
    
            } else if (props.from === 'first' && Number(props.balance) > Number(money)) {
                return <button className={css.subBtn} onClick={() => onSubmit()}>确认付款</button>
            } else if (Number(props.balance) < Number(props.addUp)) {
                return  <button className={css.subBtn} onClick={
                    () => window.location.href ='/amount'
                }>余额不足去充值</button>
    
            } 
    
            return <button className={css.subBtn} onClick={() => onSubmit()}>{props.advance ? '确认到岗，发放赏金' : '确认到岗，不打赏'}</button>
       }

        return () => (
            <div className={css.footerWrap}>
                <div className={css.footerBox}>
                    <button className={css.cannelBtn} onClick={() => onCancel()}>取消</button> 
                    <EnoughButton />
                    {/* 
                    {
                        Number(props.balance) < Number(props.addUp) ?  
                        <button className={css.subBtn} onClick={
                            () => window.location.href ='/amount'
                        }>余额不足去充值</button> : 
                        props.from === 'first' ? <button className={css.subBtn} onClick={() => onSubmit()}>确认付款</button> :
                        <button className={css.subBtn} onClick={() => onSubmit()}>
                            {props.advance ? '确认到岗，发放赏金' : '确认到岗，不打赏'}
                        </button> 
                    } */}
                </div>
            </div>
        )
    }
})


const DeductionWidget = defineComponent({
    props: {
        from: String,
        money: Number
    },
    setup (props, context) {
 
        const _checkVal = ref(0)
        const deductionArr = new Map([
            [0, ()=> (
                {
                    id: Symbol(), 
                    icon: 'https://img.fanwoon.com/cashier_6.png',
                    des: '推荐赏金将发放给简历的推荐人，有助于促使更多的人为你推荐员工，并提升职位曝光',
                }
            )],
            [1, ()=> (
                {
                    id: Symbol(), 
                    icon: 'https://img.fanwoon.com/cashier_7.png',
                    des: '不发放推荐奖金，将降低您的职位曝光，也将大大降低他人为您推荐员工的意愿',
                }
            )]
        ])

        const getRadio = computed(() => _checkVal.value === 0 ? 'https://img.fanwoon.com/cashier_5.png' 
        : 'https://img.fanwoon.com/cashier_5_1.png')


        const getInfo = (num) => deductionArr.get(num)()
        const onToggle = () => {
            _checkVal.value = _checkVal.value === 0 ? 1 : 0
            context.attrs.onToggle(_checkVal.value)
        }
    
        return () => (
                <div className={`${css.defaultCashierBox}`}>
                    <div className={`${css.headerCashierBox}`}>
                        <div className={css.cashierMidelBox}>
                            <img className={css.iconMindBox} src="https://img.fanwoon.com/cashier_4.png"/>
                            <div>
                                <div className={css.sloganBox}>
                                    {
                                        props.from === 'first' ? '推荐赏金-预扣' : '发放推荐奖金'
                                    }
                                </div>
                                <div className={css.defaultMoneyBox}>
                                    <span className={css.activeMoneyNumber}>¥{props.money}</span>
                                </div>
                            </div>
                        </div>
                        <img className={css.smallIcon} src={getRadio.value} onClick={() => onToggle()} />
                    </div>
                    <div className={`${css.descriptionBox} ${_checkVal.value === 1 ? css.importDesc: css.yesDesc}`}>
                        <img className={css.smallMidel} src={getInfo(_checkVal.value).icon} />
                        <div className={`${css.desbox} ${_checkVal.value === 1 ? css.desImp: ''}`}>{ getInfo(_checkVal.value).des }</div>
                    </div>
                </div>
            )
        }
})

const CashierWidget = defineComponent({
    props: {
        show: Boolean,
        from: String,
        data: Object,
        money: Number
    },
    setup (props, context) {
        const countAdvance = () => {
            const { addUpMoneyData } = useAddUpMoney(infos.value)
            initAdvanceMoney.value = addUpMoneyData.value
            return addUpMoneyData.value
        }
        const { data } = toRefs(props)
        const infos = ref(data)
        const initAdvanceMoney = ref(0)
        const advanceBool = ref(true) //推荐赏金-预扣

        const fixMoney = computed(() => props.from === 'first' ? 7.99 : 0) //固定扣款
        const advanceMoney = computed(() => countAdvance()) //预扣金额

        

        const onCancel = () => context.attrs.onCancel()
        const onSubmit = (e) => context.attrs.onSubmit(e)
       
        const onTaggleAdvanceMoney = (num) => {
           // advanceMoney.value = num === 1 ? Number(countAdvance()).toFixed(2) : Number(0).toFixed(2)
            initAdvanceMoney.value = num === 1 ?  Number(0.00) : Number(countAdvance()).toFixed(2)
            num === 1 ? advanceBool.value = false : advanceBool.value = true
        }

        const balanceStr = () => {
           const count = initAdvanceMoney.value 
           const cash = Number(fixMoney.value + Number(count)).toFixed(2)
           const residue = Number(Number(props.money) - Number(cash)).toFixed(2)
           return `${props.money} - ${cash} = ${ residue }`;
        }

        return () => (
            <van-popup show={props.show} 
                round 
                position="bottom" 
                style={{height: props.from === 'last' ? '46%' : props.data.recommendReward && props.data.recommendRewardRole ? '90%' : '52%' }}>
                <div className={css.wrap}>
                    <div className={css.box}>
                        <ul className={css.listBox}>
                            <li className={css.listItemBox}>
                                <img className={css.iconBox} src="https://img.fanwoon.com/cashier_1.png" />
                                <span className={css.itemTitle}>
                                    { props.from === 'first' ? '邀约面试扣款' : '确认到岗' }
                                </span>
                            </li>
                            {
                                props.from === 'first' ? (
                                    <li>
                                        <div className={`${css.headerCashierBox} ${css.defaultCashierBox}`}>
                                            <div className={css.cashierMidelBox}>
                                                <img className={css.iconMindBox} src="https://img.fanwoon.com/cashier_2.png" />
                                                <div>
                                                    <div className={css.sloganBox}>邀约面试-解锁电话号码</div>
                                                    <div className={css.defaultMoneyBox}>
                                                        <span className={css.defaultMoneyNumber}>¥12.00</span>
                                                        <span className={css.activeMoneyNumber}>¥{fixMoney.value}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <img className={css.iconMindBox} src="https://img.fanwoon.com/cashier_3.png" />
                                        </div>
                                    </li>
                                ) : null
                            }
                            
                            
                            {
                                props.data.recommendReward && props.data.recommendRewardRole ? (
                                    <li>
                                        <DeductionWidget 
                                            from={props.from}
                                            money={Number(advanceMoney.value)} 
                                            onToggle={(e) => onTaggleAdvanceMoney(e)} />
                                    </li>
                                ) : null
                            }

                            {
                                 props.from === 'first' ? (
                                    <li class={`${css.flexSpaceBetween} ${css.ptb40}`}>
                                        <span>直接扣款:</span>
                                        <span>¥{fixMoney.value}</span>
                                    </li>
                                 ) : null
                            }

                            {
                                props.from === 'first' && props.data.recommendReward && props.data.recommendRewardRole ? (
                                    <li>
                                        <div class={`${css.flexSpaceBetween}`}>
                                            <div class={css.tipBoxHeader}>
                                                <span>预扣:</span>
                                                <img className={css.tipIcon} src="https://img.fanwoon.com/cashier_8.png" />
                                            </div>
                                            <span>¥{advanceBool.value ? Number(advanceMoney.value) : 0}</span>
                                        </div>
                                        <div class={css.tipBox}>
                                            “预扣”指先支付给平台，淘汰后返还金额至您的余额，入职则将奖金发放给推荐者，若您没有操作简历，21天后将根据简历状态自动结算
                                        </div>
                                    </li>
                                ) : null
                            }

                            { props.from === 'first' ? (<li class={css.lineBox}></li>) : null }
                            
                            {
                                props.from === 'last' ? (
                                    <li class={`${css.totalBox} ${props.from === 'last' ? css.mt30 : ''}`}>
                                        <span>扣款后余额:</span>
                                        <div class={css.totalMoneyBox}>
                                            <div class={css.totalMoney}>
                                            {Number(props.money).toFixed(2)} - { Number(initAdvanceMoney.value).toFixed(2)} = 
                                            { Number(Number(props.money) - Number(initAdvanceMoney.value)).toFixed(2) }
                                            </div>
                                        </div>
                                    </li>
                                ) : null
                            }

                            {
                                props.from === 'first' ? (
                                    <li class={css.totalBox}>
                                        <span>合计:</span>
                                        <div class={css.totalMoneyBox}>
                                            <div class={css.totalMoney}>¥{ Number(Number(fixMoney.value) + Number(initAdvanceMoney.value)).toFixed(2) } </div>
                                            <div>支付后余额 <span>{balanceStr()}</span></div>
                                        </div>
                                    </li>
                                ) : null
                            }
                            
                        </ul>
                        <ButtonWidget 
                            balance={props.money }
                            addUp={Number(Number(fixMoney.value) + Number(initAdvanceMoney.value))} 
                            from={props.from}
                            advance={advanceBool.value}
                            onCancel={onCancel} 
                            onSubmit={onSubmit} />
                    </div>
                </div> 
            </van-popup>
        )
    }
})

export default CashierWidget
