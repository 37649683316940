import { ref } from 'vue'

export function useAddUpMoney (obj: any) {
    const info = obj
    const addUpMoneyData = ref(null)
     const init = () => {
        let addUpMoney = 0; //累计金额
        let addUpTotalMoney = 0; //预扣金额
        if (info.recommendReward && info.recommendRewardRole) {
            const price = JSON.parse(info.recommendRewardRole)
            if (price[0]) addUpMoney += price[0]
            if (price[200]) addUpMoney += price[200]
            if (price[400]) addUpMoney += price[400]
        }

        if (!info.payStatus) {
            addUpTotalMoney = (Number(addUpMoney) + 0)  //Number(5.99)
        } else {
            addUpTotalMoney = (Number(addUpMoney))
        }
        addUpMoneyData.value = addUpTotalMoney.toFixed(2)
    }
    init()
    return { addUpMoneyData }
}